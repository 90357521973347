<template>
  <div class="home">
    <Header 
      :currentTeam="currentTeam"
      :user="user"
      @showTeamModal="showTeamModal = true"
    />

    <main class="home__main">
      <div 
        class="app-card app-card_ready"
        v-for="(app, index) in currentTeamApps"
        :key="index"
        @click="onShowApp(app.id)"
      >
        <button 
          class="app-card__button_edit"
          @click.stop="onAppModalShow(app)"
        ></button>
        <div class="app-card__top">
          <img 
            class="logo"
            :src="getImageURL(app.image)" 
            :alt="app.title"
          >
          <div class="title">{{ app.title }}</div>
          <div class="subtitle">{{ app.subtitle }}</div>
        </div>
        <div class="app-card__bottom">
          <div class="uid">
            <input 
              type="text"
              :value="app.uid"
              ref="uid"
              class="input_uid"
            >
            <button
              class="button_copy-uid"
              ref="button_copy_uid"
              type="button"
              @click.stop="copyUid(index)"
            >Copy</button>
          </div>
        </div>
      </div>

      <div 
        class="app-card app-card_new stonly-step-1"
        @click="onNewButtonClicked()"
      >
        <span class="new-icon"></span>
        <span class="new-title">New channel</span>
      </div>
    </main>

    <transition name="cover" mode="in-out">
      <div 
        class="home__dark-cover" 
        v-if="showAppModal || showTeamModal"
      ></div>
    </transition>

    <transition name="aside" mode="in-out">
      <template v-if="showAppModal">
        <aside class="home__aside">
          <AppModal
            @close="onAppModalClose"
            :app="appToEdit"
            :currentTeamId="currentTeam.id"
          />
        </aside>
      </template>
      <template v-if="showTeamModal">
        <aside class="home__aside">
          <TeamModal
            @close="showTeamModal = false"
            :teams="teams"
            :alertMessage="alertMessage"
            @resetAlertMessage="alertMessage = ''"
            @setTeam="onSetTeam"
          />
        </aside>
      </template>
    </transition>
    <!-- end of home -->
  </div>
</template>

<script>
import Header from '@/components/Header';
import AppModal from '@/components/modals/AppModal';
import TeamModal from '@/components/modals/TeamModal';

export default {
  name: 'Home',
  components: {
    AppModal,
    TeamModal,
    Header
  },
  data: () => ({
    teams: [],
    currentTeamApps: [],
    showAppModal: false,
    showTeamModal: false,
    appToEdit: {},
    showAppDeleteModal: false,
    alertMessage: '',
    appUid: ''
  }),
  methods: {
    onNewButtonClicked() {
      const stonlyWidgetCloseButton = document.querySelectorAll('[aria-label="Close Stonly widget"]');
      if (stonlyWidgetCloseButton.length) {
        for (let i = 0; i < stonlyWidgetCloseButton.length; i++) {
          stonlyWidgetCloseButton[i].click();
        }
      }
      this.onAppModalShow({});
    },
    onAppModalShow(app) {
      this.appToEdit = app;
      this.showAppModal = true;
    },
    onAppModalClose() {
      this.appToEdit = {};
      this.showAppModal = false;
    },
    onCloseAllModals() {
      this.onAppModalClose();
      this.showTeamModal = false;
    },
    getImageURL(image) {
      if (image === null) {
        return require('../../public/img/app-default.png')
      } else {
        return this.ENV_STRAPI_HOST + image.url;
      }
    },
    onSetTeam(team) {
      this.currentTeam = team;
    },
    filterAndSortAppsByCurrentTeam() {
      if (this.currentTeam && this.currentTeam.id) {
        const apps = this.apps.filter(app => {
          // In new apps relationed field 'team' contains object 
          // In fetched apps relationed field 'team' contains id
          if (app.team && app.team.id) {
            return app.team.id === this.currentTeam.id;
          }
          return app.team === this.currentTeam.id;
        });
        this.currentTeamApps = [...apps].sort((a, b) => a.title.localeCompare(b.title));
      }
    },
    onShowApp(appId) {
      this.$router.push('/apppage/' + appId)
    },
    copyUid(index) {
      const uidInput = this.$refs['uid'][index];
      uidInput.select();
      uidInput.setSelectionRange(0, 99999);
      document.execCommand("copy");

      const copyButton = this.$refs['button_copy_uid'][index];
      copyButton.innerHTML = 'Copied';

      setTimeout(() => {
        copyButton.innerHTML = 'Copy';
      }, 3000);
    }
  },
  async mounted() {
    try {
      // Reset state
      this.$store.dispatch('resetHomeState');
      await this.$store.dispatch('checkForTemplates');

      await this.$store.dispatch('fetchApps');
      this.teams = await this.$store.dispatch('getTeams');
      if (localStorage.getItem('currentTeamName')) {
        this.currentTeam = this.teams.find(team => team.name === localStorage.getItem('currentTeamName'));
      } else {
        this.currentTeam = this.teams.find(team => team.name === this.user.login);
      }
      await this.$store.dispatch('fetchTemplates');
      await this.$store.dispatch('fetchComboIcons');
      await this.$store.dispatch('fetchTeamFeedbackIcons');
      this.$store.commit('updateAutoSaveTimer', 'Done');
    } catch (e) {
      this.$router.push('/auth');
      this.$store.dispatch('logoutUser');
    }
  },
  watch: {
    currentTeam: {
      async handler() {
        this.filterAndSortAppsByCurrentTeam();
        await this.$store.dispatch('fetchTeamFeedbackIcons');
      }
    },
    apps() {
      this.filterAndSortAppsByCurrentTeam();
    }
  },
  computed: {
    apps() {
      return this.$store.getters['apps'];
    },
    user: {
      get() {
        return this.$store.getters['user'];
      },
      set(user) {
        this.$store.commit('updateUser', user);
      }
    },
    currentTeam: {
      get() {
        return this.$store.getters['currentTeam'];
      },
      set(team) {
        this.$store.commit('updateCurrentTeam', team);
      }
    }
  }
}
</script>

<style lang="sass">
@import '../assets/variables/variables'

.home
  width: 1760px
  margin: 0 auto

  .home 
    width: 1760px

  &__main
    display: flex
    flex-wrap: wrap
    padding-top: 20px
    .app-card
      display: flex
      flex-direction: column
      justify-content: space-between
      padding: 30px
      width: 320px
      height: 320px
      border: 1px solid #bbc3ca
      margin-bottom: 40px
      box-shadow: 14.142px 14.142px 20px 0px rgba(93, 93, 93, 0.15)
      position: relative
      transition: .1s linear
      &:not(:nth-child(5n))
        margin-right: 40px   

      &__deleting-status,
      &__button_edit
        display: block
        width: 19px 
        height: 19px
        border: none
        background: none
        background-image: url('../assets/img/edit.png')
        position: absolute
        top: 30px
        right: 30px
        background-repeat: no-repeat
        background-position: center
        &:hover
          background-image: url('../assets/img/edit_hover.png')
          cursor: pointer

      &__top
        .logo
          display: block
          height: 80px
          margin: 0 auto
        .title
          font-family: $lato
          font-weight: 900
          font-size: 18px
          color: #41494d
          text-align: center
          margin-top: 10px
        .subtitle
          font-family: $lato
          font-weight: 400
          margin-top: 10px
          color: #41494d
          text-align: center
          line-height: 1.3em
      &__bottom
        border-top: 1px solid #bbc3ca
        height: 30px
        display: flex
        justify-content: center
        align-items: flex-end
        .uid
          position: relative
          width: 100%
          .input_uid
            border: none
            background: transparent
            font-family: $lato
            font-weight: 400
            font-size: 14px
            text-align: center
            width: 100%
            &:hover
              cursor: default
          .button_copy-uid 
            border: none
            background: transparent
            font-family: $lato
            color: $pink
            font-weight: 400
            font-size: 14px
            position: absolute
            right: 0
            transition: .1s linear
            &:hover
              cursor: pointer
              color: $red
      &:hover
        border-color: $red
        cursor: pointer

      &_new
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        .new-icon
          display: block
          width: 20px
          height: 20px
          background-image: url('../assets/img/plus.png')
          background-repeat: no-repeat
          background-size: contain
        .new-title
          display: block
          text-transform: uppercase
          margin-top: 5px
          color: $pink
          font-family: $lato
          font-weight: 400
        &:hover
          .new-icon
            background-image: url('../assets/img/plus_hover.png')
          .new-title
            color: $red

  &__aside
    width: 480px
    height: 100vh
    position: fixed
    top: 0
    right: 0
    overflow: hidden
    box-shadow: -4px 0px 10px -6px #000

  &__dark-cover
    width: 100vw
    height: 100vh
    overflow: hiddden
    position: fixed
    top: 0
    left: 0
    background-color: rgba(#000, 0.15)

// Transitions
.cover-enter, .cover-leave-to 
  opacity: 0

.cover-enter-active, .cover-leave-active
  transition: all .3s linear

.aside-enter, .aside-leave-to
  transform: translateX(100%)

.aside-enter-active, .aside-leave-active
  transition: all .3s ease

// Media queries
@media screen and (max-width: 1760px)
  .home 
    width: 1400px
    &__main
      .app-card
        &:not(:nth-child(5n))
          margin-right: 0px
        &:not(:nth-child(4n))
          margin-right: 40px

@media screen and (max-width: 1400px)
  .home 
    width: 1040px
    &__main
      .app-card
        &:not(:nth-child(4n))
          margin-right: 0px
        &:not(:nth-child(3n))
          margin-right: 40px

@media screen and (max-width: 1040px)
  .home 
    width: 680px
    &__main
      .app-card
        &:not(:nth-child(3n))
          margin-right: 0px
        &:not(:nth-child(2n))
          margin-right: 40px

@media screen and (max-width: 680px)
  .home
    width: 320px
    &__main
      padding-bottom: 100px
      .app-card
        height: auto
        padding: 20px 15px
        margin-bottom: 25px
        &:not(:nth-child(2n))
          margin-right: 0px
        &_new
          flex-direction: row
          .new-title
            margin: 0 15px
        &__top
          padding-bottom: 15px
          .logo
            float: left
            height: 60px
          .title,
          .subtitle
            text-align: left
            padding-left: 85px
            margin-top: 0
            padding-right: 33px
          .title
            font-size: 16px
            margin-bottom: 8px
          .subtitle
            font-size: 15px
        &__button_edit
          top: 20px
          right: 15px
    &__aside
      width: 100%
        
</style>