import axios from 'axios';
import shortid from 'shortid';

export default {
  state: {
    apps: [],
    currentApp: {
      image: {
        url: ''
      }
    }
  },
  mutations: {
    updateApps(state, apps) {
      state.apps = apps;
    },
    addApp(state, app) {
      state.apps.push(app);
    },
    updateCurrentApp(state, currentApp) {
      state.currentApp = currentApp;
    },
    resetAppState(state) {
      state.apps = [];
      state.currentApp = { 
        image: {
          url: ''
        }
      }
    }
  },
  getters: {
    apps: s => s.apps,
    currentApp: s => s.currentApp
  },
  actions: {
    async fetchApps({dispatch, commit, getters}) {
      try {
        // get apps from strapi
        const response = await axios.get(getters.STRAPI_HOST + '/apps/', getters.requestHeader);
        // update apps list
        const apps = response.data;
        commit('updateApps', apps);
      } catch (e) { 
        dispatch('setError', e); 
      }
    },
    async fetchApp({dispatch, commit, getters}, appId) {
      try {
        // get app by id
        const response = await axios.get(getters.STRAPI_HOST + '/apps/' + appId, getters.requestHeader);
        // update current app in state
        const currentApp = response.data;
        commit('updateCurrentApp', currentApp);
        // get related banners
        const banners = currentApp.banners;
        // update current app's banners in state
        commit('updateBanners', banners)
      } catch (e) { 
        dispatch('setError', e); 
       }
    },
    async addApp({dispatch, getters, commit}, {appData, teamId}) {
      try {
        // generate uid for app
        const uid = shortid.generate();
        appData.uid = uid;
        // process form data
        const formData = await dispatch('getFormData', appData);
        // push app to strapi
        const response = await axios.post(getters.STRAPI_HOST + '/apps?team=' + teamId, formData, getters.requestHeader);
        const newApp = response.data;
        // Add app to states
        commit('addApp', newApp)
        // add app log
        const appLog = {
          "action": "App created",
          "app_name": appData.title,
          "app_id": appData.uid,
          "date": new Date(),
          "username": getters.user.email
        };
        await axios.post(getters.STRAPI_HOST + '/logs/', appLog, getters.requestHeader);
      } catch (e) { 
        dispatch('setError', e); 
       }
    },
    async editApp({dispatch, getters, commit}, {appData, appId}) {
      try {
        // process form data
        const formData = await dispatch('getFormData', appData);
        // update app by id
        const response = await axios.put(getters.STRAPI_HOST + '/apps/' + appId, formData, getters.requestHeader);
        const editedApp = response.data;
        // update app state
        const appIndex = await dispatch('getAppIndex', appId);
        getters['apps'].splice(appIndex, 1, editedApp);
        // Check if current app changed -> update
        if (getters['currentApp'].id === appId) {
          commit('updateCurrentApp', editedApp);
        }
      } catch (e) { 
        dispatch('setError', e); 
       }
    },
    async deleteApp({dispatch, getters}, appId) {
      try {
        // Get app from strapi
        const response = await axios.get(getters.STRAPI_HOST + '/apps/' + appId, getters.requestHeader);
        const appToDelete = response.data;
        // Find all banners related with this app
        const bannersToDelete = appToDelete.banners;
        // Delete each related banner
        for (let i = 0; i < bannersToDelete.length; i++) {
          await dispatch('deleteBanner', bannersToDelete[i]);
        }
        // delete app by id
        await axios.delete(getters.STRAPI_HOST + '/apps/' + appId, getters.requestHeader);
        // add app log
        const appIndex = await dispatch('getAppIndex', appId);
        const appData = getters['apps'][appIndex];
        const appLog = {
          "action": "App removed",
          "app_name": appData.title,
          "app_id": appData.uid,
          "date": new Date(),
          "username": getters.user.email
        };
        await axios.post(getters.STRAPI_HOST + '/logs/', appLog, getters.requestHeader);
        // update app state
        getters['apps'].splice(appIndex, 1);
      } catch (e) { 
        dispatch('setError', e); 
      }
    },
    async getAppIndex({getters}, appId) {
      const appIndex = getters['apps'].findIndex(a => a.id == appId);
      return appIndex;
    }
  }
}
