import axios from 'axios';
import dateFilter from '../../filters/date.filter';

export default {
  state: {
    viewsCounter: 0,
    feedbackStat: {
      good: 0,
      neutral: 0,
      bad: 0
    }
  },
  getters: {
    viewsCounter: s => s.viewsCounter,
    feedbackStat: s => s.feedbackStat
  },
  mutations: {
    updateViewsCounter(state, viewsCounter) {
      state.viewsCounter = viewsCounter;
    },
    updateFeedbackStat(state, feedbackStat) {
      state.feedbackStat = feedbackStat;
    }
  },
  actions: {
    async generateHtml({ dispatch, getters }, { fieldsGroups, bannerParams }) {
      let groupsHTML = "";
      for (let i = 0; i < fieldsGroups.length; i++) {
        
        let fieldListIcon = '';
        let fieldList = '';

        for (let j = 0; j < fieldsGroups[i].length; j++) {
          if (fieldsGroups[i][j].type === 'combo') {
            let imageSrc;
            if (fieldsGroups[i][j].image) {
              imageSrc = await dispatch('getImageExportURL', { image: fieldsGroups[i][j].image, type: 'default' });
            } else {
              imageSrc = await dispatch('getImageExportURL', { image: fieldsGroups[i][j].value, type: 'combo' })
            }
            const field = '\n\t<div style="width: 64px; margin-right: 10px;">\n\t\t<img src="' + imageSrc + '" style="width: 100%">\n\t</div>';
            fieldListIcon = field;
          } else {
            let styles = '';
            for (let [key, value] of Object.entries(fieldsGroups[i][j].styles)) {
              if (key === 'border-top' || key === 'border-bottom') {
                continue;
              }
              if (key === 'margin-bottom' || key === 'margin-top') {
                value += 'px'
              }
              if (key === 'font-size') {
                value += 'px'
              }
              if (key === 'height') {
                value = value === 'auto'
                        ? value
                        : value + 'px'
              }
              if (key === 'width') {
                value = value === 'auto'
                        || value === '100%'
                        ? value
                        : value + 'px'
              }
              const style = '' + key + ': ' + value + '; ';
              styles += style;
            }

            let fieldValue = '';
            if (fieldsGroups[i][j].type === 'date') {
              fieldValue = '' + dateFilter(fieldsGroups[i][j].value);

            } else if (fieldsGroups[i][j].type === 'headerh1' 
                      || fieldsGroups[i][j].type === 'headerh2' 
                      || fieldsGroups[i][j].type === 'headerh1group' 
                      || fieldsGroups[i][j].type === 'headerh2group') {
              fieldValue = '' + fieldsGroups[i][j].value;

            } else if (fieldsGroups[i][j].type === 'text' 
                      || fieldsGroups[i][j].type === 'textgroup') {
              const lines = fieldsGroups[i][j].value.split(/\r?\n/g);
              const textHTML = lines.reduce((sum, line, index) => {
                return index === 0
                      ? sum + line
                      : sum + '<br/>' + line
              }, '');
              fieldValue = '' + textHTML;

            } else if ((fieldsGroups[i][j].type === 'image' 
                      || fieldsGroups[i][j].type === 'imagegroup') && fieldsGroups[i][j].image) {
              const imageWidth = fieldsGroups[i][j].styles['width'] === 'auto'
                          || fieldsGroups[i][j].styles['width'] === '100%'
                          ? fieldsGroups[i][j].styles['width']
                          : fieldsGroups[i][j].styles['width'] + 'px'
              let imageSrc = await dispatch('getImageExportURL', { image: fieldsGroups[i][j].image, type: 'default'});
              fieldValue = '<img style="max-width: 100%; object-fit: inherit; width:' + imageWidth + ';" src="' + imageSrc + '">'
            
            } else if ((fieldsGroups[i][j].type === 'youtube'
                      || fieldsGroups[i][j].type === 'youtubegroup') && fieldsGroups[i][j].value) {
              fieldValue = '<iframe style="height: inherit; width: 100%;" src="' + fieldsGroups[i][j].value 
                            + '" frameborder="0" allowfullscreen></iframe>';
            
            } else if ((fieldsGroups[i][j].type === 'bulletpoints' 
                      || fieldsGroups[i][j].type === 'bulletpointsgroup') && fieldsGroups[i][j].value.length) {
              const linesValues = fieldsGroups[i][j].value.split('/bl');
              let linesHTML = '';
              for (let i = 0; i < linesValues.length; i++) {
                linesHTML += '\n\t\t\t\t<li style="font-size: inherit">' + linesValues[i] + '</li>';
              }
              fieldValue = '<ul style="font-size: inherit; padding-left: 20px;">' + linesHTML + '\n\t\t\t</ul>'
            } else if (fieldsGroups[i][j].type === 'feedback') {
              const assets = getters.teamFeedbackIcons;
              const assetId = fieldsGroups[i][j].value.split('=')[1];
              const asset = assets.find(a => a.id == assetId);
              const assetUrls = asset.data;
              console.log({ assetUrls })
              fieldValue = "";
              if (assetUrls.goodDefault) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.goodDefault.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.goodDefault;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_good_default" 
                                style="margin-right: 5px; cursor: pointer;"
                                src=${imageSrc}
                                title="${assetUrls.goodTitle ? assetUrls.goodTitle : ''}"
                              >`; 
              } 
              if (assetUrls.goodHover) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.goodHover.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.goodHover;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_good_hover" 
                                style="margin-right: 5px; display: none; cursor: pointer;"
                                src=${imageSrc}
                                title="${assetUrls.goodTitle ? assetUrls.goodTitle : ''}"
                                >`; 
              }
              if (assetUrls.goodActive) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.goodActive.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.goodActive;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_good_active" 
                                style="margin-right: 5px; display: none; cursor: pointer;"
                                src=${imageSrc}
                                title="${assetUrls.goodTitle ? assetUrls.goodTitle : ''}"
                                >`; 
              }
              if (assetUrls.neutralDefault) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.neutralDefault.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.neutralDefault;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_neutral_default" 
                                style="margin-right: 5px; cursor: pointer;"
                                src=${imageSrc}
                                title="${assetUrls.neutralTitle ? assetUrls.neutralTitle : ''}"
                                >`;
              }
              if (assetUrls.neutralHover) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.neutralHover.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.neutralHover;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_neutral_hover" 
                                style="margin-right: 5px; display: none; cursor: pointer;"
                                src=${imageSrc}
                                title="${assetUrls.neutralTitle ? assetUrls.neutralTitle : ''}"
                                >`;
              } 
              if (assetUrls.neutralActive) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.neutralActive.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.neutralActive;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_neutral_active" 
                                style="margin-right: 5px; display: none; cursor: pointer;"
                                src=${imageSrc}
                                title="${assetUrls.neutralTitle ? assetUrls.neutralTitle : ''}"
                                >`;
              }
              if (assetUrls.badDefault) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.badDefault.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.badDefault;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_bad_default" 
                                style="cursor: pointer;"
                                src=${imageSrc}
                                title="${assetUrls.badTitle ? assetUrls.badTitle : ''}"
                                >`; 
              }
              if (assetUrls.badHover) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.badHover.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.badHover;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_bad_hover" 
                                style="cursor: pointer; display: none;"
                                src=${imageSrc}
                                title="${assetUrls.badTitle ? assetUrls.badTitle : ''}"
                                >`; 
              }  
              if (assetUrls.badActive) {
                const imageSrc = 'https://appversion.info/getfile.php?name=' + assetUrls.badActive.replace('/uploads/', '');
                // const imageSrc = getters.STRAPI_HOST + assetUrls.badActive;
                fieldValue += `<img 
                                width="36px" 
                                height="36px" 
                                id="feedback_bad_active" 
                                style="display: none; cursor: pointer;"
                                src=${imageSrc}
                                title="${assetUrls.badTitle ? assetUrls.badTitle : ''}"
                                >`; 
              }
              styles += "display: flex;";
            }

            let field = '\n\t\t<div style="' + styles + 'width: inherit;">\n\t\t\t' + fieldValue + '\n\t\t</div>';
            if (fieldsGroups[i][j].action_url.length) {
              field = '\n<a style="display: block; text-decoration: none;" target="_blank" href="' + fieldsGroups[i][j].action_url +'">' + field + '\n</a>';
            }
            fieldList += field;
          }
        }
        const fieldsGroupStyle = 'background-color: ' + (fieldsGroups[i][0].type === 'combo' ? fieldsGroups[i][0].styles['background-color'] : 'transparent') + '; '
                                + 'padding-top: ' + (fieldsGroups[i][0].type === 'combo' ? '15px' : '0') + '; '
                                + 'padding-bottom: ' +  (fieldsGroups[i][0].type === 'combo' ? '15px' : '0') + '; '
                                + 'padding-left: ' + bannerParams.styles['padding-left'] + '; '
                                + 'padding-right: ' + bannerParams.styles['padding-right'] + '; '
                                + 'display: flex;'
        const fieldsGroup = '<div style="' + fieldsGroupStyle + '">' + fieldListIcon 
                            + '\n\t<div style="flex: 1;">' + fieldList + '\n\t</div>\n</div>'  
        groupsHTML += '\n' + fieldsGroup;
      }

      // Add banner styles
      
      let bannerStyles = ""
      for (let [key, value] of Object.entries(bannerParams.styles)) {
          if (key === 'height') {
            value = bannerParams.size.height + 'px';
          } else if (key === 'width') {
            value = bannerParams.size.width + 'px';
          } else if (key === 'padding-left' || key === 'padding-right') {
            continue;
          }
          bannerStyles += '' + key + ': ' + value + '; ';
      }
      bannerStyles += 'box-sizing: border-box;';

      let resultHTML = '<!DOCTYPE html>'  
                        + '\n<html lang="en">'
                        + '\n<head>'
                        + '\n\t<meta charset="UTF-8">'
                        + '\n\t<meta name="viewport" content="width=device-width, initial-scale=1.0">'
                        + '\n\t<link href="https://fonts.googleapis.com/css2?family=Muli&display=swap" rel="stylesheet">'
                        + '\n\t<link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap" rel="stylesheet">'
                        + '\n\t<link href="https://fonts.googleapis.com/css?family=Open+Sans:400,300,500,600&subset=latin,latin-ext" rel="stylesheet" type="text/css">'
                        + '\n</head>'
                        + '\n<body style="padding: 0; margin: 0; overflow: hidden;">'
                        + '\n\t<div style="' + bannerStyles + '" id="banner_container">'
                        + '\n\t\t' + groupsHTML
                        + '\n\t</div>'
                        + '\n</body>'
                        + '\n</html>' 

      return resultHTML;
    },
    // getImageExportURL({ getters }, { image, type }) {
    getImageExportURL(_, { image, type }) {
      if (type === 'combo') {
        return 'https://appversion.info/getfile.php?name=' + image.replace('/uploads/', '');
      } else if (image?.hash && image?.ext) {
        return 'https://appversion.info/getfile.php?name=' + image.hash + image.ext;
        // return getters.STRAPI_HOST + image.url;
      } else {
        return '';
      }
    },
    async exportHtml({getters, dispatch, commit}, {html, banner, app_uid}) {
      try {
        console.log({ banner })
        // define related export
        const relatedExport = banner.export_id;
        // define banner id
        const bannerId = banner.id;
        const release_version = banner.release_version;
        const banner_styles = banner.styles;
        const min_client_version = banner.min_client_version;
        const exportData = {
          html,
          app_uid,
          release_version,
          banner_styles,
          min_client_version
        };

        let response = '';
        if (relatedExport === null) {
          // if first export
          // add to db
          exportData.views = 0;
          exportData.feedback = {
            "good": "0",
            "neutral": "0",
            "bad": "0"
          }

          response = await axios.post(getters.STRAPI_HOST + `/exports?app=${banner.app.id}`, exportData, getters.requestHeader);
          // define export id
          const export_id = response.data.id;
          // set related export to banner
          response = await axios.put(getters.STRAPI_HOST + `/banners/${bannerId}`, {export_id}, getters.requestHeader)
          // update banner in state
          banner = response.data;
          commit('replaceBanner', banner);
        } else {
          // else update exists export
          response = await axios.put(getters.STRAPI_HOST + `/exports/${relatedExport}`, exportData, getters.requestHeader);
        }
        return banner;
      } catch (e) { 
        dispatch('setError', e); 
      }
    },
    async deleteExportHtml({dispatch, getters}, exportId) {
      try {
        await axios.delete(getters.STRAPI_HOST + `/exports/${exportId}`, getters.requestHeader);
      } catch (e) {
        dispatch('setError', e); 
      }
    },
    async fetchBannerStat({dispatch, getters, commit}, export_id) {
      try {
        // get export by id
        const response = await axios.get(getters.STRAPI_HOST + `/exports/${export_id}`, getters.requestHeader);
        const views = response.data.views;
        const feedbackStat = response.data.feedback;
        commit('updateFeedbackStat', feedbackStat);
        commit('updateViewsCounter', views);
      } catch (e) { 
        dispatch('setError', e); 
       }
    }
  }
}