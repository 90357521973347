import router from '../../router';

export default {
	state: {
		error: ''
	},
	mutations: {
		updateError(state, error) {
			console.log('Error: ', error);
			state.error = error;
		}
	},
	actions: {
		setError({ dispatch, commit, getters }, error) {
			if (error && error.response && error.response.data && (error.response.data.statusCode === 403 || error.response.data.statusCode === 401)) {
				if (getters.token.length) {
					dispatch('logoutUser');
					router.push('/auth');
					alert('Session time is expired. Please login again');
				}
				return;
			}
			commit('updateError', error);
		}
	}
}