<template>
    <div class="team-modal">
        <transition name="message" mode="out-in">
            <div class="team-modal__error" v-if="error">{{ error }}</div>
            <div class="team-modal__success" v-else-if="success">{{ success }}</div>
        </transition>
        
        <h2 class="team-modal__title">Team settings</h2>

        <transition name="form-change" mode="out-in">
            <form 
                class="team-modal__form"
                v-if="formMode === 'default'" 
                @submit.prevent=""
            >
                <div class="input-container">
                    <label class="input-title">Active team:</label>
                    <div class="entry-container">
                        <span class="entry-name" :class="{'admin': isTeamAdmin(currentTeam)}">{{ currentTeam.name }}</span>
                        <button
                            class="entry-button"
                            type="button"
                            @click="formMode = 'teamList'"
                        >Change</button>
                    </div>
                </div>
                <div class="input-container">
                    <label class="input-title">Members:</label>

                    <ul class="entry-list">
                        <li
                            class="entry-container"
                            v-for="(member, index) in currentTeamMembers"
                            :key="index"
                        >
                            <span class="entry-name">{{ member.email }}</span>
                            <button
                                class="entry-button"
                                type="button"
                                v-if="isTeamAdmin(currentTeam) && member.email !== user.email"
                                @click="onRemoveMember(member)"
                            >Remove</button>
                        </li>
                    </ul>
                </div>
                <div class="input-container" v-if="isTeamAdmin(currentTeam)">
                    <label class="input-title" for="new-member">Add member:</label>
                    <input 
                        type="text"
                        class="input-text"
                        placeholder="Enter user's email "
                        v-model="newMemberMail"
                    >
                </div>
                <div class="buttons-container">
                    <button
                        class="input-button"
                        type="button"
                        @click="$emit('close')"
                    >Close</button>
                    <button
                        class="input-button"
                        type="button"
                        @click="onAddNewMember"
                        v-if="isTeamAdmin(currentTeam)"
                    >Add</button>
                </div>
            </form>
        </transition>
        <!-- Select team -->
        <transition name="form-change" mode="in-out">
            <form 
                class="team-modal__form"
                v-if="formMode === 'teamList'"
                @submit.prevent=""
            >
                <div class="input-container">
                    <label class="input-title">Your teams:</label>

                    <ul class="entry-list">
                        <li 
                            class="entry-container"
                            v-for="(team, index) in teams"
                            :key="index"
                        >
                            <span class="entry-name" :class="{'admin': isTeamAdmin(team)}">{{ team.name }}</span>
                            <button
                                class="entry-button"
                                type="button"
                                @click="onSetTeam(team)"
                            >Select</button>
                        </li>        
                    </ul>
                </div>
                <div class="buttons-container">
                    <button
                        class="input-button"
                        type="button"
                        @click="formMode = 'default'"
                    >Cancel</button>
                </div>
            </form>
        </transition>
    </div>
</template>

<script>
import ModalMessageMixin from '@/mixins/ModalMessageMixin';

export default {
    props: ['teams', 'alertMessage'],
    mixins: [ModalMessageMixin],
    data: () => ({
        currentTeamMembers: [],
        newMemberMail: '',
        newTeamName: '',
        showTeamsList: false,
        showDeleteTeamTip: false,
        formMode: 'default',
    }),
    mounted() {
        if (this.alertMessage.length) {
            this.setMessage('error', this.alertMessage);
            this.$emit('resetAlertMessage');
        }
    },
    methods: {
        onSetTeam(team) {
            this.$emit('setTeam', team);
            this.formMode = 'default';
        },
        isTeamAdmin(team) {
            return team.admin.id === this.user.id; 
        },
        async onAddNewMember() {
            if (!this.newMemberMail.length) {
                this.setMessage('error', 'Enter user\'s email');
            } else if (!(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.newMemberMail))) {
                this.setMessage('error', 'Invalid email format');
            } else {
                try {
                    const newMember = await this.$store.dispatch('addMemberToTeam', { newMemberMail: this.newMemberMail, teamId: this.currentTeam.id });
                    this.currentTeamMembers.push(newMember);
                    this.setMessage('success', `Added ${this.newMemberMail}`);
                    this.newMemberMail = '';
                } catch (e) {
                    this.setMessage('error', e.response.data.message);
                } 
            }
        },
        async onRemoveMember(teamMember) {
            try { 
                await this.$store.dispatch('removeMemberFromTeam', { teamId: this.currentTeam.id, email: teamMember.email });
                const memberIndex = this.currentTeamMembers.findIndex(member => member.id === teamMember.id);
                this.currentTeamMembers.splice(memberIndex, 1);
                this.setMessage('success', `${teamMember.email} has been removed from the list`);
            } catch (e) {
                this.setMessage('error', `Error on removing user `);
                console.log(e);
            }
        }
    },
    computed: {
        currentTeam() {
            return this.$store.getters['currentTeam'];
        },
        user() {
            return this.$store.getters['user'];
        }
    },
    watch: {
        currentTeam: {
            handler() {
                this.currentTeamMembers = this.currentTeam.members;
            },
            immediate: true
        }
    }
}
</script>

<style lang="sass" scoped>
@import '../../assets/mixins/mixins'

.team-modal
    @include modal
    padding: 176px 20px

.form-change-enter, .form-change-leave-to
  opacity: 0
  transform: translateX(-40%)

.form-change-enter-active, .form-change-leave-active
  transition: all .2s ease

.message-enter, .message-leave-to
  opacity: 0

.message-enter-active, .message-leave-active
  transition: all .2s ease

@media screen and (max-width: 680px)
    .team-modal
        @include modal-max-680

</style>