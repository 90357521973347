import Vue from 'vue';
import Vuex from 'vuex';

import app from './modules/app.module';
import banner from './modules/banner.module';
import form from './modules/form.module';
import field from './modules/field.module';
import template from './modules/template.module';
import auth from './modules/auth.module';
import team from './modules/team.module';
import exporthtml from './modules/exporthtml.module';
import autosave from './modules/autosave.module';
import image from './modules/image.module';
import error from './modules/error.module';
import backup from './modules/backup.module';
import assets from './modules/assets.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    STRAPI_HOST: '',
    loader: false,
    scrollBannerlist: false,
    fieldsColors: {
      "date": "#0353a4",
      "header1": "#023e7d",
      "header2": "#002855",
      "text": "#001845",
      "image": "#001233",
      "video": "#33415c",
      "bulletpoints": "#5c677d",
      "group": "#7d8597" 
    }
  },
  getters: {
    STRAPI_HOST: s => s.STRAPI_HOST,
    loader: s => s.loader,
    fieldsColors: s => s.fieldsColors,
    scrollBannerlist: s => s.scrollBannerlist
  },
  mutations: {
    UPDATE_STRAPI_HOST(state, STRAPI_HOST) {
      state.STRAPI_HOST = STRAPI_HOST;
    }, 
    updateLoader(state, loader) {
      state.loader = loader;
    },
    updateScrollBannerlist(state, scrollBannerlist) {
      state.scrollBannerlist = scrollBannerlist;
    }
  },
  actions: {
    resetHomeState({ commit }) {
      commit('resetAppState');
      commit('resetBannerState');
      commit('resetAssetsState');
      commit('resetFieldState');
      commit('resetTemplateState');
    }
  },
  modules: {
    app, 
    banner, 
    form, 
    field, 
    template, 
    auth, 
    exporthtml, 
    autosave, 
    image, 
    team, 
    error,
    backup,
    assets
  }
})
