<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script type="text/javascript">
export default {
  created() {
    this.$store.commit('UPDATE_STRAPI_HOST', this.ENV_STRAPI_HOST);
  },
  mounted() {
    window.onbeforeunload = () => {
      if (!this.keepLogged) {
        this.$store.dispatch('logoutUser');
      }
    }
  },
  computed: {
    keepLogged() {
      return this.$store.getters.keepLogged;
    }
  }
}
</script>

<style lang="sass">
@import './assets/fonts/fonts'
@import './assets/variables/variables'

*
  padding: 0
  margin: 0
  outline: 0
  box-sizing: border-box

body
  overflow: hidden

::-webkit-scrollbar
  width: 7px
  background: #fff
  border: 1px solid #e8e8e8
  border-radius: 5px

::-webkit-scrollbar-thumb
  border-radius: 5px
  background-color: $pink

#app
  width: 100%
  height: 100vh
  overflow: auto
  scrollbar-width: thin
  scrollbar-color: $pink #f0f0f0

.tooltip
  position: absolute
  font-family: 'Lato', sans-serif
  z-index: 9
  display: inline-block
  background-color: #fff
  padding: 2px 5px
  font-size: 13px
  box-shadow: 2px 2px 7px -5px #000
  color: #444

</style>
