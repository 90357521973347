import axios from 'axios';

export default {
    actions: {
        async deleteImage({getters}, imageId) {
            return await axios.delete(`${getters.STRAPI_HOST}/upload/files/${imageId}`, getters.requestHeader);
        },
        async getImageDuplicate({ dispatch, getters }, [imageUrl, imageName]) {
            try {
                const response = await axios.get(`${getters.STRAPI_HOST}${imageUrl}`, {
                    responseType: 'blob'
                }, getters.requestHeader);
                const mimeType = response.headers['content-type'];
                const imageFile = new File([response.data], imageName, { type: mimeType });

                const formData = new FormData();
                formData.append('files', imageFile, imageName);

                const imageDuplicateResponse = await axios.post(getters.STRAPI_HOST + '/upload', formData, getters.requestHeader); 
                return imageDuplicateResponse.data[0];

            } catch (e) { 
                dispatch('setError', e);     
            } 
        },
        async postImage({ getters }, image) {
            const formData = new FormData();
            formData.append('files', image);
            const iconsResponse = await axios.post(getters.STRAPI_HOST + '/upload', formData, getters.requestHeader);
            return iconsResponse.data[0];
		},

        async fetchImageObjectByURL({ getters }, url) {
            const response = await axios.get(getters.STRAPI_HOST + '/upload/files?url=' + url, getters.requestHeader); 
            return response.data[0];
        } 
    }
}