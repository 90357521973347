import axios from 'axios';

export default {
	state: {
		imageFiles: []
	},
	getters: {
		imageFiles: s => s.imageFiles
	},
	mutations: {
		appendImageFile(state, imageFile) {
			state.imageFiles.push(imageFile);
		},
		clearImageFiles(state) {
			state.imageFiles = [];
		}
	},
	actions: {
		async authServer(_, { strapiLink, data }) {
			return await axios.post(strapiLink + '/auth/local', data, { timeout: 3000 });
		},

		async getBackup(_, { strapiLink, jwt }) {
			const response = await axios.get(strapiLink + '/backup/create', { headers: { 'Authorization': 'Bearer ' + jwt } }); 
			return response.data;
		},
		async postBackup(_, { strapiLink, jwt, data }) {
			return await axios.post(strapiLink + '/backup/upload', data, { headers: { 'Authorization': 'Bearer ' + jwt } });
		},

		async fetchFiles(_, { strapiLink, jwt }) {
			const response = await axios.get(strapiLink + '/upload/files?_limit=-1', { headers: { 'Authorization': 'Bearer ' + jwt } });
			return response.data;
		},
		async fetchImageByURL(_, { strapiLink, imageUrl, imageName, jwt }) {
			const response = await axios.get(strapiLink + imageUrl, {
				responseType: 'blob', 
				headers: { 'Authorization': 'Bearer ' + jwt } 
			});
			const mimeType = response.headers['content-type'];
			const imageFile = new File([response.data], imageName, { type: mimeType });
			return imageFile;
		},

		async uploadAppendedImages({ getters, commit }, { strapiLink, jwt }) {
			const formData = new FormData();
			const imageFiles = getters.imageFiles;
			for (let i = 0; i < imageFiles.length; i++) {
				formData.append('files', imageFiles[i]);
			}
			const response = await axios.post(strapiLink + '/upload/', formData, { headers: { 'Authorization': 'Bearer ' + jwt } }); 
			commit('clearImageFiles');
			return response.data;
		},

		async uploadImage(_, { strapiLink, imageFile, imageName, jwt }) {
			const formData = new FormData();
			formData.append('files', imageFile, imageName);
			const response = await axios.post(strapiLink + '/upload/', formData, { headers: { 'Authorization': 'Bearer ' + jwt } }); 
			return response.data[0];
		}
	}
}