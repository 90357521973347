module.exports = [
    // Single types
    {
        type: "banner",
        styles: {
            "width": "438",
            "height": "648",
            "padding-top": "15",
            "padding-bottom": "15",
            "padding-left": "29",
            "padding-right": "29",
            "background": "#ffffff"
        }
    },
    {
        type: "date",
        styles: {
            "font-family": "Muli",
            "line-height": "1.2",
            "font-size": "14",
            "color": "#a0a0a0",
            "margin-top": "0",
            "margin-bottom": "0"
        }
    },
    {
        type: "headerh1",
        styles: {
            "font-family": "Poppins",
            "color": "#2B579A",
            "font-size": "27",
            "line-height": "1.5",
            "font-weight": "bold",
            "font-style": "normal",
            "text-decoration": "none",
            "text-shadow": "none",
            "text-align": "left",
            "margin-bottom": "7",
            "margin-top": "9"
        }
    },
    {
        type: "headerh2",
        styles: {
            "font-family": "Open Sans",
            "color": "#444444",
            "font-size": "25",
            "line-height": "1.2",
            "font-weight": "500",
            "font-style": "normal",
            "text-decoration": "none",
            "text-shadow": "none",
            "text-align": "center",
            "margin-bottom": "9",
            "margin-top": "13"
        }
    },
    {
        type: "text",
        styles: {
            "font-family": "Muli",
            "color": "#444444",
            "font-size": "17",
            "line-height": "1.2",
            "font-weight": "normal",
            "font-style": "normal",
            "text-decoration": "none",
            "text-shadow": "none",
            "text-align": "justify",
            "margin-bottom": "13",
            "margin-top": "10"
        }
    },
    {
        type: "image",
        styles: {
            "display": "flex",
            "justify-content": "center",
            "margin-bottom": "10",
            "height": "auto",
            "width": "100%",
            "object-fit": "contain",
            "border-top": "none",
            "border-bottom": "none",
            "margin-top": "10"
        }
    },
    {
        type: "youtube",
        styles: {
            "display": "flex",
            "justify-content": "center",
            "margin-bottom": "5",
            "width": "100%",
            "height": "230",
            "margin-top": "5"
        }
    },
    {
        type: "bulletpoints",
        styles: {
            "font-family": "Muli",
            "color": "#444444",
            "font-size": "17",
            "line-height": "1.5",
            "font-weight": "normal",
            "font-style": "normal",
            "text-shadow": "none",
            "text-decoration": "none",
            "text-align": "left",
            "margin-bottom": "10",
            "margin-top": "10"
        }
    },
    {
        type: "combo",
        styles: {}
    },
    {
        type: "feedback",
        styles: {
            "margin-bottom": "0",
            "margin-top": "9",
            "justify-content": "flex-start"
        }
    },
    
    // Group types
    {
        type: 'headerh1group',
        styles: {
            "font-family": "Poppins",
            "color": "#de9905",
            "font-size": "13",
            "line-height": "1.5",
            "font-weight": "normal",
            "font-style": "normal",
            "text-decoration": "none",
            "text-shadow": "none",
            "text-align": "left",
            "margin-bottom": "0",
            "margin-top": "0"
        }
    },
    {
        type: 'headerh2group',
        styles: {
            "font-family": "Poppins",
            "color": "#de9905",
            "font-size": "20",
            "line-height": "1.3",
            "font-weight": "normal",
            "font-style": "normal",
            "text-decoration": "none",
            "text-shadow": "none",
            "text-align": "left",
            "margin-bottom": "10",
            "margin-top": "0"
        }
    },
    {
        type: "textgroup",
        styles: {
        "font-family": "Muli",
        "color": "#444444",
        "font-size": "17",
        "line-height": "1.2",
        "font-weight": "normal",
        "font-style": "normal",
        "text-decoration": "none",
        "text-shadow": "none",
        "text-align": "justify",
        "margin-bottom": "0",
        "margin-top": "0"
        }
    },
    {
        type: "imagegroup",
        styles: {
        "display": "flex",
        "justify-content": "center",
        "margin-bottom": "10",
        "height": "auto",
        "width": "100%",
        "object-fit": "contain",
        "border-top": "none",
        "border-bottom": "none",
        "margin-top": "10"
        }
    },
    {
        type: "youtubegroup",
        styles: {
        "display": "flex",
        "justify-content": "center",
        "margin-bottom": "5",
        "width": "100%",
        "height": "auto",
        "margin-top": "5"
        }
    },
    {
        type: "bulletpointsgroup",
        styles: {
        "font-family": "Muli",
        "color": "#444444",
        "font-size": "17",
        "line-height": "1.5",
        "font-weight": "normal",
        "font-style": "normal",
        "text-shadow": "none",
        "text-decoration": "none",
        "text-align": "left",
        "margin-bottom": "10",
        "margin-top": "10"
        }
    }
  ];