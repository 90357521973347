import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import autosize from 'autosize';
import tooltipDirective from './directives/tooltip.directive'

// import VTooltip from 'v-tooltip';

// filters
import dateFilter from '@/filters/date.filter';

Vue.config.productionTip = false;

Vue.filter('date', dateFilter);

Vue.directive('tooltip', tooltipDirective);

// Vue.use(VTooltip);

Vue.prototype.$eventBus = new Vue();
Vue.prototype.$autosize = autosize;

// define enviroment variables which can be changed in dist bundle
const getRuntimeConfig = async () => {
  const runtimeConfig = await fetch('/runtimeConfig.json');
  return await runtimeConfig.json()
};
getRuntimeConfig().then(function(json) {
  Vue.mixin({
    data() {
      return {
        ENV_STRAPI_HOST : json.ENV_STRAPI_HOST
      }
    }
  });
}).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});
