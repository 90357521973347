import axios from 'axios';
import basicTemplates from '../../data/templates/basicTemplates.data';

export default {
  state: {
    templates: {}
  },
  getters: {
    templates: s => s.templates
  },
  mutations: {
    updateTemplates(state, templates) {
      state.templates = templates;
    },
    updateTemplate(state, {template, templateIndex}) {
      state.templates.splice(templateIndex, 1, template);
    },
    resetTemplateState(state) {
      state.templates = {};
    }
  },
  actions: {
    async checkForTemplates({dispatch, getters}) {
      try {
        const response = await axios.get(getters.STRAPI_HOST + '/templates/', getters.requestHeader);
        const templates = response.data;
        if (templates.length === 0) {
          await dispatch('fetchBasicTemplates');
        }
      } catch (e) {
        dispatch('setError', e);
      }
    },
    async fetchBasicTemplates({dispatch, getters}) {
      try {
        // for each template push to db
        for (let i = 0; i < basicTemplates.length; i++) {
          // console.log(basicTemplates[i]);
          const formData = await dispatch('getFormData', basicTemplates[i])
          await axios.post(getters.STRAPI_HOST + '/templates/', formData, getters.requestHeader);
        }
        // fetch templates to state
        dispatch('fetchTemplates');
      } catch (e) { 
        dispatch('setError', e);  
      }
    },
    async fetchTemplates({dispatch, commit, getters}) {
      try {
        // get templates from db
        const response = await axios.get(getters.STRAPI_HOST + '/templates/', getters.requestHeader);
        const templates = response.data;
        //update templates state
        commit('updateTemplates', templates);
      } catch (e) { 
        dispatch('setError', e); 
      }
    },
    async saveTemplate({commit, getters}, template) {
      // get template id
      const templateIndex = getters['templates'].findIndex(t => t.type === template.type);
      const templateId = getters['templates'][templateIndex].id;
      // update template in db
      const response = await axios.put(getters.STRAPI_HOST + '/templates/' + templateId, template, getters.requestHeader);
      // update template in state
      template = response.data
      commit('updateTemplate', {template, templateIndex});
    }
  }
}
