import axios from 'axios';

export default {
  state: {
    token: localStorage.getItem('user-token') || '',
    user: JSON.parse(localStorage.getItem('user')) || {},
    keepLogged: true
  },
  getters: {
    token: s => s.token,
    user: s => s.user,
    keepLogged: s => s.keepLogged,
    requestHeader: s => {
      return {
        'headers': {
          'Authorization': `Bearer ${s.token}`
        }
      }
    }
  },
  mutations: {
    updateToken(state, token) {
      state.token = token;
      localStorage.setItem('user-token', token);
    },
    updateUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    updateKeepLogged(state, keepLogged) {
      state.keepLogged = keepLogged;
    }
  },
  actions: {
    async registerUser({commit, getters}, regData) {
      // register new user
      const response = await axios.post(getters.STRAPI_HOST + '/auth/local/register/', regData);
      // get token and user data from response
      const jwt = response.data.jwt;
      const user = response.data.user;
      // update token and user data in state
      commit('updateToken', jwt);
      commit('updateUser', user);
    },
    async loginUser({commit, getters}, { logData, keepLogged }) {
      // login user
      const response = await axios.post(getters.STRAPI_HOST + '/auth/local/', logData);
      // get token and user data from response
      const jwt = response.data.jwt;
      const user = response.data.user;
      if (user.role.name === 'Administrator') {
        user.password = logData.password;
      }
      // update token and user data in state
      commit('updateToken', jwt);
      commit('updateUser', user);
      commit('updateKeepLogged', keepLogged);
      return user;
    },
    logoutUser({commit, dispatch}) {
      // remove user data and token
      commit('updateToken', '');
      commit('updateUser', {});
      dispatch('resetHomeState');
      commit('resetTeamState');
    },
    async forgotPassword({ getters }, email) {
      return await axios.post(getters.STRAPI_HOST + '/auth/forgot-password', { 
        email
      });
    },
    async resetPassword({ getters }, data) {
      return await axios.post(getters.STRAPI_HOST + '/auth/reset-password', data);
    }
  }
}
