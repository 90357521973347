import axios from 'axios';

export default {
  state: {
    currentTeam: {}
  },
  getters: {
    currentTeam: s => s.currentTeam
  },
  mutations: {
    updateCurrentTeam(state, team) {
      state.currentTeam = team;
      localStorage.setItem('currentTeamName', team.name);
    },
    resetTeamState(state) {
      state.currentTeam = {};
      localStorage.removeItem('currentTeamName');
    }
  },
  actions: {
    async getTeams({ dispatch, getters }) {
      try {
        const response = await axios.get(`${getters.STRAPI_HOST}/teams`, getters.requestHeader);
        return response.data;
      } catch(e) {
        dispatch('setError', e);
      }
    },
    async createTeam({ getters }, { name }) {
      const response = await axios.post(`${getters.STRAPI_HOST}/teams`, { name }, getters.requestHeader);
      return response.data;
    },
    async deleteTeam({ getters }, { teamId }) {
      await axios.delete(`${getters.STRAPI_HOST}/teams/${teamId}`, getters.requestHeader);
    },
    async addMemberToTeam({ getters }, { newMemberMail, teamId }) {
      const response = await axios.post(`${getters.STRAPI_HOST}/teams/${teamId}/invite/${newMemberMail}`, {}, getters.requestHeader);
      return response.data;
    },
    async removeMemberFromTeam({ getters }, { teamId, email }) {
      await axios.delete(`${getters.STRAPI_HOST}/teams/${teamId}/kick/${email}`, getters.requestHeader);
    }
  }
}