export default {
  state: {
    autoSaveTimer: 'Done',
    autoSaveQuery: 0
  },
  getters: {
    autoSaveTimer: s => s.autoSaveTimer,
    autoSaveQuery: s => s.autoSaveQuery
  },
  mutations: {
    updateAutoSaveTimer(state, value) {
      state.autoSaveTimer = value;
    },
    updateAutoSaveQuery(state, value) {
      state.autoSaveQuery = value;
    }
  }
}
