<template>
  <header class="header">
    <div class="header-top">
      <img src="/logo.png" class="logo" alt="logo">
        <template v-if="$route.name === 'Dashboard'">
          <nav class="nav-container">
            <button class="nav-link nav-link_button" v-if="user.role && user.role.type === 'administrator'" @click="$router.push('/admin')">Admin Panel</button>
            <button class="nav-link nav-link_button" @click="$emit('showTeamModal')">Team {{ currentTeam ? currentTeam.name : '' }}</button>
            <button class="nav-link nav-link_button" @click="onLogout">{{ user.email }}</button>
          </nav>
        </template>
        <template v-if="$route.name === 'AppPage'">
            <transition name="nav-toggle">
              <nav class="nav-container" v-if="autoSaveTimer === 'Done'">
                <router-link class="nav-link" to="/">Dashboard</router-link>
                <button class="nav-link nav-link_button" @click="onLogout">{{ user.email }}</button>
              </nav>
            </transition>
        </template>
        <template v-if="$route.name === 'Admin'">
          <nav class="nav-container">
            <button class="nav-link nav-link_button" @click="onLogout">{{ user.email }}</button>
          </nav>
        </template>
    </div>
    <div class="header-bottom" v-if="$route.name !== 'AppPage'">
      <h1 class="page-title">{{ toUpperCase($route.name) }}</h1>
    </div>
    <div class="header-bottom" v-else>
      <h1 class="page-title">{{ currentApp.title }}</h1>
      <div class="app-info">
        <img 
          class="app-info__image"
          :src="getImageURL(currentApp.image)" 
          :alt="currentApp.title"
          v-if="isSaved === undefined"
        >
        <div 
          class="app-info__autosave"
          ref="autosave"
          v-if="isSaved !== undefined"
        >
          <img 
            class="icon icon_saved"
            :src="require('../assets/img/autosave_saved_large.gif')"
            v-if="isSaved === true" 
            alt="Saved"
          >
          <img 
            class="icon icon_saving"
            :src="require('../assets/img/autosave_saving_large.gif')"
            v-else-if="isSaved === false"
            alt="Saving..."
          >
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  mounted() {
    console.log(this.autoSaveTimer);
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    currentTeam: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    isSaved: undefined,
    isSavedTimer: null
  }),
  computed: {
    currentApp() {
      return this.$store.getters['currentApp'];
    },
    autoSaveTimer() {
      return this.$store.getters['autoSaveTimer'];
    }
  },
  methods: {
    onLogout() {
      this.$router.push('/auth');
      this.$store.dispatch('logoutUser');
    },
    getImageURL(image) {
      if (image === null || !image.url) {
        return require('../../public/img/app-default.png')
      } else {
        return this.ENV_STRAPI_HOST + image.url;
      }
    },
    toUpperCase(text) {
      return text.toUpperCase()
    }
  },
  watch: {
    activeBanner(banner) {
      if (banner && banner.export_id) { 
        this.isSaved = true;
      }
    }, 
    autoSaveTimer: {
      handler (value) {
        console.log(value);
        // define autosave info element
        if (value > 0) {
          // while seconds left
          // disable 'saved' class
          this.isSaved = false;
          // update timer
          clearTimeout(this.timerTimeout);
          this.timerTimeout = setTimeout(() => {
            this.$store.commit('updateAutoSaveTimer', value - 1);
          }, 1000);
        } else if (value === 0){
          // on last second
          this.$eventBus.$emit('save');
        } else if (value === 'Done') {
          // on saved
          this.isSaved = true;
        }
      }
    },
    isSaved(value) {
      clearTimeout(this.isSavedTimer);
      if (value === true) {
        this.isSavedTimer = setTimeout(() => {
          this.isSaved = undefined;
        }, 2000)
      }
    }
  }
}
</script>

<style lang="sass">
@import '../assets/variables/variables'

.header
  width: 100%
  &-top
    display: flex
    justify-content: space-between
    align-items: center
    height: 100px
    position: relative
    .logo
      width: 180px
      height: 50px
    .nav-container
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: 0
      &_autosaving
        z-index: 99
    .nav-link
      font-family: $cabin
      font-weight: 400
      font-size: 18px
      color: #5d5d5d
      text-decoration: none
      position: relative
      &_button
        background: none
        border: none
      &_disabled
        &:hover
          cursor: auto !important
          text-decoration: none !important
      &:not(:last-child)
        margin-right: 30px
        &::after
          content: '|'
          position: absolute
          right: -15px
      &:hover
        cursor: pointer
        text-decoration: underline
  &-bottom
    display: flex
    align-items: center
    .app-info
      position: relative
      margin-left: 25px
      font-family: $lato
      color: $grey-text
      &__image
        width: 38px
        height: 38px
      &__autosave
        width: 38px
        height: 38px

    .page-title
      color: $grey-title
      font-family: $lato
      font-weight: 900
      font-size: 56px
      line-height: 56px

.nav-toggle-enter, .nav-toggle-leave-to
  opacity: 0

.nav-toggle-enter-active, .nav-toggle-leave-active
  transition: all .2s ease

@media screen and (max-width: 680px)
  .header
    &-top
      padding-top: 20px
      flex-direction: column
      height: auto
      align-items: flex-start
      .logo
        height: auto
        width: 120px
      .nav-container
        position: relative
        margin-top: 30px
    &-bottom
      margin-top: 15px
      flex-direction: column
      .page-title
        font-size: 36px
        width: 100%
        text-align: left
      .app-info
        margin: 10px 0
</style>