import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import store from '../store';

Vue.use(VueRouter);

  const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/apppage/:id',
    name: 'AppPage',
    component: () => import('../views/AppPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/auth/',
    name: 'Auth',
    component: () => import('../views/Auth.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Admin' && to.name !== 'Auth' && store.getters.user?.role?.type === 'administrator') {
    next('/admin');
    return;
  }

  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.token.length) {
      next('/auth');
      return;
    }
  } 

  if(to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters.user.role.type !== 'administrator') {
      next('/');
      return;
    }
  }
  
  next();
})

export default router;
