import axios from 'axios'

export default {
	state: {
		teamFeedbackIcons: [],
		comboIcons: []
	},
	getters: {
		teamFeedbackIcons: s => s.teamFeedbackIcons,
		comboIcons: s => s.comboIcons
	},
	mutations: {
		updateTeamFeedbackIcons(state, teamFeedbackIcons) {
			state.teamFeedbackIcons = teamFeedbackIcons;
		},
		updateComboIcons(state, comboIcons) {
			state.comboIcons = comboIcons;
		}, 
		resetAssetsState(state) {
			state.teamFeedbackIcons = [];
			state.comboIcons = [];
		}
	},
	actions: {
		async fetchAssets(_, { strapiLink, jwt }) {
			const response = await axios.get(strapiLink + '/assets/', { headers: { 'Authorization': 'Bearer ' + jwt } });
			return response.data
		},
		async postAsset(_, { asset, strapiLink, jwt }) {
			const response = await axios.post(strapiLink + '/assets', asset, { headers: { 'Authorization': 'Bearer ' + jwt } });
			return response.data;
		},
		async updateAsset({ getters }, { asset, assetId }) {
			const response = await axios.put(getters.STRAPI_HOST + '/assets/' + assetId, asset, getters.requestHeader);
			return response.data;
		},
		async deleteAssetById({ getters }, assetId) {
			const response = await axios.delete(getters.STRAPI_HOST + '/assets/' + assetId, getters.requestHeader);
			return response.data;
		},

		async fetchComboIcons({ getters, dispatch, commit }) {
			try {
				const response = await axios.get(getters.STRAPI_HOST + '/assets?name_contains=comboIcon', getters.requestHeader);
				let comboIcons = response.data;
				comboIcons = comboIcons.map(i => i.data);
				commit('updateComboIcons', comboIcons);
				return comboIcons;
			} catch (e) {
				dispatch('setError');
			}
		},
		async fetchTeamFeedbackIcons({ getters, dispatch, commit }) {
			try {
				const defaultFeedbackIconsResponse = await axios.get(getters.STRAPI_HOST + '/assets?name_contains=feedbackIconsDefault', getters.requestHeader);
				const defaultFeedbackIcons = defaultFeedbackIconsResponse.data;
				const customTeamFeedbackIcons = getters.currentTeam.assets;
				const allTeamFeedbackIcons = defaultFeedbackIcons.concat(customTeamFeedbackIcons);
				commit('updateTeamFeedbackIcons', allTeamFeedbackIcons);
				return allTeamFeedbackIcons;
			} catch (e) {
				dispatch('setError', e);
			}
		}
	}
}