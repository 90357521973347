import axios from 'axios';

export default {
  state: {
    currentBannerFields: [],
    activeFieldIndex: {}
  },
  getters: {
    currentBannerFields: s => s.currentBannerFields,
    activeFieldIndex: s => s.activeFieldIndex
  },
  mutations: {
    updateCurrentBannerFields(state, currentBannerFields) {
      state.currentBannerFields = currentBannerFields.sort((a, b) => a.field_index - b.field_index);
    },
    updateCurrenBannerFieldByIndex(state, field) {
      state.currentBannerFields = state.currentBannerFields.map(f => {
        if (f.field_index === field.field_index) return field;
        else return f;
      })
    },
    addCurrentBannerField(state, { field, field_index }) {
      state.currentBannerFields.splice(field_index, 0, field)
    },
    removeCurrentBannerFieldByIndex(state, fieldIndex) {
      state.currentBannerFields = state.currentBannerFields.filter(field => field.field_index !== fieldIndex);
    },
    updateActiveFieldIndex(state, activeFieldIndex) {
      state.activeFieldIndex = activeFieldIndex;
    },
    resetFieldState(state) {
      state.currentBannerFields = [];
      state.activeFieldIndex = {};
    }
  },
  actions: {
    async fetchFields({dispatch, commit, getters}) {
      try {
        // get fields from strapi
        const response = await axios.get(getters.STRAPI_HOST + '/fields/', getters.requestHeader);
        // update fields list in state
        const fields = response.data;
        commit('updateActiveFields', fields);
      } catch (e) { 
        dispatch('setError', e); 
      }
    },
    async loadField({dispatch, getters}, field) {
      try {
        // process form data
        const formData = await dispatch('getFormData', field);
        // add field
        const bannerId = field.banner;
        const response = await axios.post(getters.STRAPI_HOST + `/fields?banner=${bannerId}`, formData, getters.requestHeader);
        return response.data;
      } catch (e) { 
        dispatch('setError', e); 
      }
    },
    async updateField({dispatch, getters}, field) {
      try {
        // process form data
        const formData = await dispatch('getFormData', field);
        // update field
        const response = await axios.put(getters.STRAPI_HOST + '/fields/' + field.id, formData, getters.requestHeader);
        
        if ((field.type === 'image' || field.type === 'combo') && field.image) {
          const fieldIndex = await dispatch('getFieldIndex', field);
          getters['currentBannerFields'][fieldIndex].image = response.data.image;
        }

        return response.data;
      } catch (e) { 
        dispatch('setError', e);  
      }
    },
    async deleteField({dispatch, getters}, field) {
      try {
        // delete from db
        if (field.id) {
          await axios.delete(getters.STRAPI_HOST + '/fields/' + field.id, getters.requestHeader);
          
          if ((field.type === 'image' || field.type === 'combo') && field.image && field.image.id) {
            dispatch('deleteImage', field.image.id);
          }
        }
      } catch (e) { 
        dispatch('setError', e); 
      }
    },
    async getFieldIndex({getters}, field) {
      const fieldIndex = getters['currentBannerFields'].findIndex(f => f.uid == field.uid);
      return fieldIndex;
    }
  }
}
