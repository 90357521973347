<script>
export default {
  data: () => ({
    success: '',
    error: '',
    messageTimeout: ''
  }),
  methods: {
    setMessage(type, text) {
      this.error = '';
      this.success = '';
      clearTimeout(this.messageTimeout);

      if (type === 'error') {
        this.error = text;
      } else if (type === 'success') {
        this.success = text;
      }

      this.messageTimeout = setTimeout(() => {
        this.error = '';
        this.success = '';
      }, 7000);
    }
  }
}
</script>