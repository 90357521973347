export default {
  actions: {
    async getFormData(_, itemData) {
      const formData = new FormData();
      const data = {};

      for (const [key, value] of Object.entries(itemData)) {
        if (key === 'image' && value) {
          // If image has url it means that image is already in db and it dont need to append as file
          if (!(Object.prototype.hasOwnProperty.call(itemData.image, "url"))) {
            formData.append('files.image', value, value.name);
          } else {
           data[key] = value;
          }
        } else {
          data[key] = value;
        }
      }

      formData.append("data", JSON.stringify(data));
      return formData;
    }
  }
}
