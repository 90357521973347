<template>
  <div class="app-modal">
    <transition name="message" mode="out-in">
      <div class="app-modal__error" v-if="error">{{ error }}</div>
    </transition>

    <h2 class="app-modal__title">{{ modalTitle }}</h2>

    <form class="app-modal__form stonly-step-2" @submit.prevent="onSubmit">
      <div class="input-container">
        <label class="input-title" for="logo">Logo:</label>
        <img 
          class="logo"
          :src="createImageURL(image)" 
          :alt="title"
        >
        <button 
          type="button"
          class="input-button"
          @click="$refs['input_image'].click()"
        >
          Change
        </button>
        <input
          id="logo"
          class="input-image"
          type="file"
          accept="image/*"
          @change="onImageChange"
          ref="input_image"
        >
      </div>
      <div class="input-container">
        <label class="input-title" for="title">Title:</label>
        <input
          id="title"
          class="input-text"
          type="text"
          v-model="title"
          placeholder="e.g. app title"
        >
      </div>
      <div class="input-container">
        <label class="input-title" for="subtitle">Subtitle:</label>
        <input
          id="title"
          class="input-text"
          type="text"
          v-model="subtitle"
          placeholder="e.g. app subtitle (optional)"
        >
      </div>
      <div class="loader-container" v-if="loader">
        <img :src="require('../../assets/img/autosave_saving.gif')">
      </div>
      <transition name="buttons-show">
        <div class="buttons-container" v-if="!loader">
          <div 
            class="delete-contanier"
            :class="{'active': showDeleteConfirm}"
          >
            <button
              class="input-button input-button_delete"
              v-if="mode === 'edit'"
              type="button"
              @click="showDeleteConfirm = true"
            >Delete channel</button>
            <div 
              class="confirm-container"
              v-if="showDeleteConfirm"
            >
              <p class="text-attention">
                Are you sure? Remember, you cannot undo this action! Your work will be unable to restore.
              </p>
              <button
                type="button"
                class="input-button_confirm"
                @click.once="onDeleteApp"
              >Confirm delete</button>
            </div>
          </div>
          <button
            class="input-button"
            type="button"
            @click="$emit('close')"
          >Cancel</button>
          <button
            class="input-button"
            type="submit"
          >{{ mode === 'edit' ? 'Save' : 'Add' }}</button>
        </div>
      </transition>
    </form>
  </div>
</template>

<script type="text/javascript">
import ModalMessageMixin from '@/mixins/ModalMessageMixin';

export default {
  props: ['app', 'currentTeamId'],
  mixins: [ModalMessageMixin],
  data: () => ({
    modalTitle: 'New channel',
    mode: 'add',
    image: null,
    title: '',
    subtitle: '',
    maxSubtitleLength: '',
    loader: false,
    allowSubmit: true,

    showDeleteConfirm: false,
    showDeleteConfirmTimeout: null
  }),
  methods: {
    onImageChange(e) {
      const targetImage = e.target.files[0];
      if (targetImage.size > 1048576) {
        this.setMessage('error', 'Maximum image size - 1MB ');
        this.$refs['input_image'].value = "";
        return;
      }
      this.image = targetImage;
    },
    createImageURL(image) {
      if (image === null) {
        return require('../../../public/img/app-default.png');
      } else if ('url' in image) {
        return this.ENV_STRAPI_HOST + image.url;
      } else {
        return URL.createObjectURL(image);
      }
    },
    onSubmit() {
      if (this.mode === 'add') this.onAddApp();
      else if (this.mode === 'edit') this.onEditApp();
    },
    async onAddApp() {
      if (this.validateForm() && this.allowSubmit) {
        this.allowSubmit = false;
        const appData = {
          title: this.title,
          subtitle: this.subtitle,
          image: this.image
        };
        await this.$store.dispatch('addApp', { appData, teamId: this.currentTeamId });
        this.$emit('close');
      }
    },
    async onEditApp() {
      if (this.validateForm() && this.allowSubmit) {
        this.allowSubmit = false;
        const appData = {
          title: this.title,
          subtitle: this.subtitle,
          image: this.image
        };
        const appId = this.app.id;

        await this.$store.dispatch('editApp', { appData, appId });
        this.$emit('close');
      }
    },
    async onDeleteApp() {
      this.showDeleteConfirm = false;
      this.loader = true;
      const appId = this.app.id;
      await this.$store.dispatch('deleteApp', appId);

      if (this.app.image) {
        const imageId = this.app.image.id;
        await this.$store.dispatch('deleteImage', imageId);
      }
      
      this.$emit('close');
      // Check if current app deleted -> go to Dashboard
      if (this.$store.getters['currentApp'].id === appId) {
        this.$router.push('/');
      }
    },
    validateForm() {
      if (!this.title) {
        this.setMessage('error', 'Enter app name');
        return false;
      }
        return true;
    }
  },
  mounted() {
    if (Object.entries(this.app).length) {
      this.mode = 'edit';
      this.modalTitle = 'Edit channel';
      this.image = this.app.image;
      this.title = this.app.title;
      this.subtitle = this.app.subtitle;
    }
  },
  watch: {
    showDeleteConfirm: {
      handler(value) {
        if (value) {
          clearTimeout(this.showDeleteConfirmTimeout)
          this.showDeleteConfirmTimeout = setTimeout(() => {
            this.showDeleteConfirm = false;
          }, 3000);
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../assets/mixins/mixins'

.app-modal
  @include modal
  padding: 176px 0 20px
  .input-title
    width: 70px !important
  .input-image
    display: none
  .logo
    height: 70px
    margin-right: 20px
  &__form
    .buttons-container
      align-items: flex-start
      .delete-contanier
        position: relative
        width: 180px
        margin-right: auto
        text-align: center
        &.active 
          box-shadow: 10px 10px 15px 0px rgba(93, 93, 93, 0.15)
          border: 1px solid #bbc3ca
          padding: 15px
          transform: translateY(-16px)
        .input-button_delete
          margin-left: 0
        .confirm-container
          font-family: $lato
          font-size: 12px
          display: flex 
          flex-direction: column 
          justify-content: center
          .text-attention
            // display: block
            margin: 10px 0
          .input-button_confirm
            font-family: $lato
            text-transform: uppercase
            border: none 
            background: #c42929
            color: #fff
            padding: 8px 0
            font-size: 14px
            &:hover 
              cursor: pointer 
              background: rgba(#c42929, 0.9)

.message-enter-active, .message-leave-active
  transition: all .2s ease

@media screen and (max-width: 680px)
  .app-modal
    @include modal-max-680
    .input-container
      .input-button
        position: absolute
        bottom: 25px
        left: 90px
</style>
