import axios from 'axios';

export default {
  state: {
    banners: [],
    activeBanner: {}
  },
  getters: {
    banners: s => s.banners,
    activeBanner: s => s.activeBanner
  },
  mutations: {
    updateBanners(state, banners) {
      state.banners = banners;
    },
    addBanner(state, banner) {
      state.banners.push(banner);
    },
    removeBannerById(state, bannerId) {
      state.banners = state.banners.filter(banner => banner.id !== bannerId);
    },
    replaceBanner(state, banner) {
      state.banners = state.banners.map(b => {
        if (b.id === banner.id) return banner;
        else return b;
      });
    },
    updateActiveBanner(state, banner) {
      state.activeBanner = banner;
    },
    resetBannerState(state) {
      state.banners = [];
      state.activeBanner = {};
    }
  },
  actions: {
    async setActiveBanner({dispatch, commit}, banner) {
      try {
        commit('updateActiveFieldIndex', -1);
        commit('updateViewsCounter', '-');
        const bannerId = banner.id;
        
        if (!Object.entries(banner).length) {
          commit('updateActiveBanner', { id: bannerId })
          commit('updateCurrentBannerFields', [])
          return;
        }
        // Fetch banner and realted fields, set this banner as active
        await dispatch('fetchBanner', bannerId);
        // Fetch banner views
        if (banner.export_id !== null) {
          await dispatch('fetchBannerStat', banner.export_id);
        } else {
          commit('updateViewsCounter', 0);
          commit('updateFeedbackStat', { 'good': '0', 'neutral': '0', 'bad': '0' });
        }
      } catch(e) { 
        dispatch('setError', e); 
       }
    },
    async fetchBanner({dispatch, commit, getters}, bannerId) {
      try {
        // This update is for increase change active banner animation speed
        commit('updateActiveBanner', { id: bannerId });
        // get banner by id
        const response = await axios.get(getters.STRAPI_HOST + '/banners/' + bannerId, getters.requestHeader);
        // update activeBanner
        const activeBanner = response.data;
        commit('updateActiveBanner', activeBanner);
        // get related fields from response
        const relatedFields = activeBanner.fields;
        // update active fields in state
        commit('updateCurrentBannerFields', relatedFields)
      } catch (e) { 
        dispatch('setError', e); 
       }
    },
    async getAppBanners({ dispatch, getters }, appId) {
      try {
        const response = await axios.get(getters.STRAPI_HOST + '/apps/' + appId, getters.requestHeader);
        return response.data.banners;
      } catch (e) {
        dispatch('setError', e); 
      }
    },
    async addBanner({dispatch, commit, getters}, newBannerData) {
      try {
        const appIndex = await dispatch('getAppIndex', newBannerData.app)
        const appData = getters['apps'][appIndex];
        // process form data
        const formData = await dispatch('getFormData', newBannerData);
        // add banner
        const response = await axios.post(getters.STRAPI_HOST + `/banners?app=${appData.id}`, formData, getters.requestHeader);
        const banner = response.data;
        // add banner log
        const bannerLog = {
          "action": "Banner created",
          "app_name": appData.title,
          "app_id": appData.uid,
          "banner_version": newBannerData.release_version,
          "banner_comment": newBannerData.comment,
          "date": new Date(),
          "username": getters.user.email
        };
        await axios.post(getters.STRAPI_HOST + '/logs', bannerLog, getters.requestHeader);
        // update banners
        commit('addBanner', banner);
        // clear currentBannerFields state
        commit('updateCurrentBannerFields', []);
        // set this banner as active
        commit('updateViewsCounter', 0);
        commit('updateActiveBanner', banner);
      } catch (e) { 
        dispatch('setError', e); 
       }
    },
    async editBanner({dispatch, getters, commit}, {newBannerData, bannerId}) {
      try {
        // process form data
        const formData = await dispatch('getFormData', newBannerData);
        // update banner by id
        const response = await axios.put(getters.STRAPI_HOST + '/banners/' + bannerId, formData, getters.requestHeader);
        const editedBanner = response.data;
        // update banner in state
        commit('replaceBanner', editedBanner)
        // return banner
        return editedBanner;
      } catch (e) { 
        dispatch('setError', e);  
      }
    },
    async deleteBanner({dispatch, getters, commit}, banner) {
      try {
        // define banner id and export id
        const bannerId = banner.id;
        const exportId = banner.export_id;
        // update banner state
        commit('removeBannerById', bannerId);
        // find all fields related with this banner
        const fields = await dispatch('fetchSortedBannerFields', bannerId);
        // for each related field delete
        for (let i = 0; i < fields.length; i++) {
          await dispatch('deleteField', fields[i]);
        }
        // delete banner by id
        await axios.delete(getters.STRAPI_HOST + '/banners/' + bannerId, getters.requestHeader);
        // delete export if it's exists
        if (exportId !== null) {
          await dispatch('deleteExportHtml', exportId);
        }
        // add banner log
        let appId = banner.app.id;
        if (appId === undefined) appId = banner.app;
        const appIndex = await dispatch('getAppIndex', appId);
        const appData = getters['apps'][appIndex];
        const bannerLog = {
          "action": "Banner removed",
          "app_name": appData.title,
          "app_id": appData.uid,
          "banner_version": banner.release_version,
          "banner_comment": banner.comment,
          "date": new Date(),
          "username": getters.user.email
        };
        await axios.post(getters.STRAPI_HOST + '/logs', bannerLog, getters.requestHeader);
      } catch (e) { 
        dispatch('setError', e); 
       }
    },
    async refreshBannersSortIndex({dispatch, getters}, start_index) {
      const banners = getters.banners;
      const sortedBanners = [...banners].sort((a, b) => a.sort_index - b.sort_index);
      
      for (let i = start_index; i < sortedBanners.length; i++) {
        const newBannerData = { sort_index: i };
        const bannerId      = sortedBanners[i].id; 
        await dispatch('editBanner', {newBannerData, bannerId})
      }
    },
    async duplicateBanner({dispatch, commit, getters}, { bannerToDuplicate, fieldsToDuplicate }) {
      try {
        const bannerDuplicateResponse = await axios.post(getters.STRAPI_HOST + `/banners?app=${bannerToDuplicate.app}`, bannerToDuplicate, getters.requestHeader);
        const bannerDuplicate = bannerDuplicateResponse.data;

        let activeGroupId = null;
        for (let i = 0; i < fieldsToDuplicate.length; i++) {
          let image = null;
          if (fieldsToDuplicate[i].type === 'image' && fieldsToDuplicate[i].image) {
            // [imageUrl, imageName]
            image = await dispatch('getImageDuplicate', [fieldsToDuplicate[i].image.url, fieldsToDuplicate[i].image.name]);
          } else if (fieldsToDuplicate[i].type === 'combo' && fieldsToDuplicate[i].image && fieldsToDuplicate[i].image.url) {
            image = await dispatch('getImageDuplicate', [fieldsToDuplicate[i].image.url, fieldsToDuplicate[i].image.name]);
          } else {
            image = fieldsToDuplicate[i].image;
          }

          if (!fieldsToDuplicate[i].type.includes('group')) {
            activeGroupId = null;
          }

          // Make field duplicate
          const fieldToDuplicate = {
            uid:         Date.now(),
            type:        fieldsToDuplicate[i].type,
            value:        fieldsToDuplicate[i].value,
            image,
            action_url:  fieldsToDuplicate[i].action_url,
            field_index: fieldsToDuplicate[i].field_index,
            styles:      fieldsToDuplicate[i].styles,
            banner: bannerDuplicate.id,
            related_group_id: activeGroupId
          };

          const formData = await dispatch('getFormData', fieldToDuplicate);
          const fieldDuplicateResponse = await axios.post(getters.STRAPI_HOST + `/fields?banner=${bannerDuplicate.id}`, formData, getters.requestHeader);
          const fieldDuplicate = fieldDuplicateResponse.data; 

          if (fieldDuplicate.type === 'combo') {
            activeGroupId = fieldDuplicate.id;
          }
        }

        commit('addBanner', bannerDuplicate);

        return bannerDuplicate;
      } catch (e) { 
        dispatch('setError', e); 
       }
    },
    async exportBanner({dispatch, getters}, { bannerToExport, fieldsToExport, destinationAppId }) {
      try {
        const bannerExportResponse = await axios.post(getters.STRAPI_HOST + `/banners?app=${destinationAppId}`, bannerToExport, getters.requestHeader);
        const bannerExport = bannerExportResponse.data;

        let activeGroupId = null;
        for (let i = 0; i < fieldsToExport.length; i++) {
          let image = null;
          if (fieldsToExport[i].type === 'image' && fieldsToExport[i].image) {
            // [imageUrl, imageName]
            image = await dispatch('getImageDuplicate', [fieldsToExport[i].image.url, fieldsToExport[i].image.name]);
          } else if (fieldsToExport[i].type === 'combo' && fieldsToExport[i].image && fieldsToExport[i].image.url) {
            image = await dispatch('getImageDuplicate', [fieldsToExport[i].image.url, fieldsToExport[i].image.name]);
          } else {
            image = fieldsToExport[i].image;
          }

          if (!fieldsToExport[i].type.includes('group')) {
            activeGroupId = null;
          }

          const fieldToExport = {
            uid:         Date.now(),
            type:        fieldsToExport[i].type,
            value:       fieldsToExport[i].value,
            action_url:  fieldsToExport[i].action_url,
            field_index: fieldsToExport[i].field_index,
            image:       image,
            styles:      fieldsToExport[i].styles,
            related_group_id: activeGroupId
          };
          
          const formData = await dispatch('getFormData', fieldToExport);
          const fieldExportResponse = await axios.post(getters.STRAPI_HOST + `/fields?banner=${bannerExport.id}`, formData, getters.requestHeader); 
          const fieldExport = fieldExportResponse.data;

          if (fieldExport.type === 'combo') {
            activeGroupId = fieldExport.id;
          }
        }
        return bannerExport;
      } catch (e) { 
        dispatch('setError', e); 
       }
    },
    async getBannerIndex({getters}, bannerId) {
      const bannerIndex = getters['banners'].findIndex(b => b.id == bannerId);
      return bannerIndex;
    },
    async fetchSortedBannerFields({getters}, bannerId) {
      // get banner by id
      const response = await axios.get(getters.STRAPI_HOST + '/banners/' + bannerId, getters.requestHeader);
      // return related fields from response
      const fields = response.data.fields;
      const sortedFields = fields.sort((a, b) => a.field_index - b.field_index) 
      return sortedFields;
    },
    // SORT
    async defineSortIndex({dispatch}, { sorted_banners, release_version }) {
      let sort_index = sorted_banners.length;

      let i = sorted_banners.length - 1;
      while (i >= 0) {
        const a = release_version;
        const b = sorted_banners[i].release_version;
        
        if (await dispatch('compareBannersByReleaseVersion', {a, b}) === 1) {
          // if release_version < sorted_banners[i].release_version;
          // sorted_banners[i].sort_index = sort_index;
          
          const newBannerData = { sort_index };
          const bannerId = sorted_banners[i].id;
          await dispatch('editBanner', {newBannerData, bannerId}); 
          
          sort_index--;
          i--;
        } else {
          break;
        }
      }

      return sort_index;
    },
    // SORT BANNER
    async compareBannersByReleaseVersion(_, {a, b}) {
      // split release_version by '.'
      const aParts = a.split('.');
      const bParts = b.split('.');
      // find lower length parts arr
      const lowerArr = aParts.length < bParts.length 
                           ? aParts
                           : bParts; 
      const higherArr = aParts.length > bParts.length 
                           ? aParts
                           : bParts; 
      
      for (let i = 0; i < lowerArr.length; i++) {
        let aPart = aParts[i];
        let bPart = bParts[i];
        // if 3 and 123 -> make 003 and 123
        if (aPart.length !== bPart.length && !aPart.startsWith('0') && !bPart.startsWith('0')) {
          const lowerPart  = aPart.length > bPart.length ? bPart : aPart;
          const higherPart = aPart.length < bPart.length ? bPart : aPart;
          const missingPrefixZeros = higherPart.length - lowerPart.length;
          
          if (lowerPart === aPart) { 
            for (let j = 0; j < missingPrefixZeros; j++) { aPart = '0' + aPart }
          } else if (lowerPart === bPart) { 
            for (let j = 0; j < missingPrefixZeros; j++) { bPart = '0' + bPart } 
          }
        }
        // if 01 and 0001 -> make 010000 and 000100
        const requirePartLength = 6;
        const aMissingPostfixZeros = requirePartLength - aPart.length;
        const bMissingPostfixZeros = requirePartLength - bPart.length;

        for (let j = 0; j < aMissingPostfixZeros; j++) { aPart += '0'; }
        for (let j = 0; j < bMissingPostfixZeros; j++) { bPart += '0'; }
        // compare
        if (parseInt(aPart) < parseInt(bPart)) {
          return 1;
        } else if (parseInt(aPart) > parseInt(bPart)) {
          return -1;
        } else if (i === lowerArr.length - 1) {
          
          for (let j = i + 1; j < higherArr.length; j++) {
            if (higherArr[j] > 0) {
              if (higherArr[j].length === aParts.length) {
                return 1;
              } else {
                return -1;
              }
            }
          }
          return 0;
        }        
      }
    }
  }
}
